<template>
	<section class="mother col" align-x="left">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item to="/manager/about">關於金枝</el-breadcrumb-item>
			<el-breadcrumb-item>金枝的阿母</el-breadcrumb-item>
		</el-breadcrumb>
		<br>
		<quote-title align-self="center">
			<div class="col" align-x="center" gutter="10">
				<p><b>金枝的阿母</b></p>
				<h1>謝月霞</h1>
			</div>
		</quote-title>
		<br>
		<small>電腦版</small>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners" media-folder="about" :limit="1" :disabled="!checkEditable('banners')" />
				</div>
				<div class="row" align-self="bottom">
					<template v-if="checkEditable('banners')">
						<el-button @click="handleCancel('banners')" type="text">取消</el-button>
						<el-button @click="handleSave('banners')" type="success">儲存</el-button>
					</template>
					<template v-else>
						<el-button @click="handleEdit('banners')" type="text" icon="el-icon-edit">編輯</el-button>
					</template>
				</div>
			</div>
		</el-card>
		<br>
		<small>手機版</small>
		<el-card>
			<div class="row" gutter="10">
				<div>
					<upload v-model="banners_mobile" media-folder="about" :limit="1" :disabled="!checkEditable('banners_mobile')" />
				</div>
				<div class="row" align-self="bottom">
					<template v-if="checkEditable('banners_mobile')">
						<el-button @click="handleCancel('banners_mobile')" type="text">取消</el-button>
						<el-button @click="handleSave('banners_mobile')" type="success">儲存</el-button>
					</template>
					<template v-else>
						<el-button @click="handleEdit('banners_mobile')" type="text" icon="el-icon-edit">編輯</el-button>
					</template>
				</div>
			</div>
		</el-card>
		<br>
		<el-card>
			<template v-if="checkEditable('article')">
				<tinymce v-model="article" />
				<div class="center">
					<el-button @click="handleCancel('article')" type="info">取消</el-button>
					<el-button @click="handleSave('article')" type="success">儲存</el-button>
				</div>
			</template>
			<template v-else>
				<section v-html="article" />
				<div class="center">
					<el-button @click="handleEdit('article')" icon="el-icon-edit">編輯</el-button>
				</div>
			</template>
		</el-card>
		<br>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex"
export default {
	components: {
		Upload: () => import("@/components/upload"),
		Tinymce: () => import("@/components/tinymce")
	},
	data() {
		return {
			editable: new Object,
			banners: new Array,
			banners_mobile: new Array,
			article: new String
		}
	},
	computed: {
		...mapState("about", ["_about"]),
	},
	methods: {
		...mapActions("about", ["_getData", "_updateData"]),
		checkEditable(key) {
			const keys = Array.isArray(key) ? key : [key];
			return keys.every(k => this.editable[k]);
		},
		handleEdit(key) {
			const keys = Array.isArray(key) ? key : [key];
			keys.forEach(k => this.$set(this.editable, k, true));
		},
		async handleSave(key) {
			const keys = Array.isArray(key) ? key : [key];
			this.$message.success(
				await this._updateData({ db: "mother", data: this._.pick(this.$data, keys) })
			);
			keys.forEach(k => this.$set(this.editable, k, false));
		},
		handleCancel(key) {
			const keys = Array.isArray(key) ? key : [key];
			keys.forEach(k => {
				this.$set(this.$data, k, this._.cloneDeep(this._about[k]));
				this.$set(this.editable, k, false);
			});
		},
	},
	async created() {
		await this._getData("mother");
		this._.assign(this.$data, this._.cloneDeep(this._about));
	}
}
</script>